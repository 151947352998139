.errorText {
  font-size: 12px;
  font-style: italic;
  color: red;
}

.mediaPlayer {
  position: fixed;
  z-index: 10000;
  bottom:0;
  left:0;
  padding:10px;
}

.notification {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
}